exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__icon-button___3GIUD{cursor:pointer;color:#0d47ff;transition:color 0.25s ease-in-out}.style__icon-button___3GIUD[data-disabled=true]{pointer-events:none;box-shadow:none;color:#969696;background-color:#fff}.style__icon-button___3GIUD:not([data-disabled=true]):hover{color:#0034d9}.style__icon-button___3GIUD>svg{filter:unset;transition:filter 0.2s}.style__icon-button___3GIUD:not([data-disabled=true])>svg:hover{-webkit-filter:drop-shadow(0px 0px 1px rgba(0,0,0,0.25));filter:drop-shadow(0px 0px 1px rgba(0,0,0,0.25))}.style__spinner-span___3nxiA{display:flex;flex-direction:row;align-items:center;justify-content:center}\n", ""]);

// Exports
exports.locals = {
	"default-turquoise": "#0d91bd",
	"error-red": "#f9453e",
	"border-grey": "#e9e9e9",
	"white": "#fff",
	"default-blue": "#557eff",
	"glowing-blue": "#0d47ff",
	"text-black": "#555",
	"text-grey": "#778195",
	"disabled-grey-background": "#f9fafb",
	"disabled-light-grey-button": "#c2c2c2",
	"disabled-grey-text": "#a8a8a8",
	"success-green": "#03b571",
	"list-item-grey": "#f3f3f3",
	"outline-color": "#79c2db",
	"greyout": "#fafafa",
	"orange": "#f87748",
	"icon-button": "style__icon-button___3GIUD",
	"spinner-span": "style__spinner-span___3nxiA"
};