export const GET_JOBS = 'GET_JOBS';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_FAILURE = 'GET_JOBS_FAILURE';
export const CLEAR_ERROR_ON_GET_JOBS = 'CLEAR_ERROR_ON_GET_JOBS';

export const GET_JOB = 'GET_JOB';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';
export const GET_JOB_FAILURE = 'GET_JOB_FAILURE';

export const CLEAR_SELECTED_JOB = 'CLEAR_SELECTED_JOB';
export const CLEAR_JOBS = 'CLEAR_JOBS';
export const PROCESSING_GET_JOBS = 'PROCESSING_GET_JOBS';

export const CREATE_JOB = 'CREATE_JOB';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const PROCESSING_CREATE_JOB = 'PROCESSING_CREATE_JOB';
export const CREATE_JOB_FAILURE = 'CREATE_JOB_FAILURE';
export const CLEAR_ERROR_ON_CREATE_JOB = 'CLEAR_ERROR_ON_CREATE_JOB';

export const EDIT_JOB = 'EDIT_JOB';
export const EDIT_JOB_ID = 'EDIT_JOB_ID';
export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS';
export const ERROR_ON_JOB_ACTION = 'ERROR_ON_JOB_ACTION';

export const STOP_RUNNING_JOB = 'STOP_RUNNING_JOB';
export const STOP_RUNNING_JOB_SUCCESS = 'STOP_RUNNING_JOB_SUCCESS';
export const STOP_RUNNING_JOB_FAILURE = 'STOP_RUNNING_JOB_FAILURE';
export const CLEAR_ERROR_ON_JOB_FAILURE = 'CLEAR_ERROR_ON_JOB_FAILURE';
export const CLEAR_STOP_JOB_SUCCESS = 'CLEAR_STOP_JOB_SUCCESS';

export const DELETE_JOB = 'DELETE_JOB';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAILURE = 'DELETE_JOB_FAILURE';
export const CLEAR_ERROR_DELETE_JOB = 'CLEAR_ERROR_DELETE_JOB';
export const PROCESSING_DELETE_JOB = 'PROCESSING_DELETE_JOB';
export const CLEAR_DELETE_JOB_SUCCESS = 'CLEAR_DELETE_JOB_SUCCESS';

export const IS_LOADING = 'IS_LOADING';
